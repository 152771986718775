import React, { useCallback, useState } from "react";
import { Alert, Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Turnstile from "react-turnstile";

const ContactFormCareers = ({ settings, className }) => {
  const {
    form_title_careers: title,
    form_name: name,
    form_email: email,
    form_phone: phone,
    form_upload_cv: uploadCv,
    form_message: message,
    form_send: send,
    form_info_careers: info,
    form_success: formSuccess,
    form_error: formError,
  } = settings.data;

  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);

    fetch("/api/forms/submit", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((res) => res.json())
      .then((res) => {
        const { success } = res;

        if (success) {
          e.target.reset();
          setSubmitStatus({ message: formSuccess, type: "success" });
        } else {
          setSubmitStatus({ message: formError, type: "danger" });
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form className={className} style={{ maxWidth: 677 }} onSubmit={onSubmit}>
        <input type="hidden" name="Form" value="Career" />
        <h3 className="h6 fw-bold mb-3">{title}</h3>
        <FloatingLabel controlId="career.name" label={name} className="mb-2">
          <Form.Control type="text" placeholder={name} name="Name" />
        </FloatingLabel>
        <FloatingLabel controlId="career.email" label={email} className="mb-2">
          <Form.Control
            type="email"
            placeholder={email}
            name="Email"
            required
          />
        </FloatingLabel>
        <FloatingLabel controlId="career.tel" label={phone} className="mb-2">
          <Form.Control type="tel" placeholder={phone} name="Phone" />
        </FloatingLabel>
        <Form.Group
          controlId="career.uploadcv"
          className="d-sm-flex align-items-center gap-3 mb-2"
        >
          <Form.Label className="mb-sm-0 text-nowrap">{uploadCv}</Form.Label>
          <Form.Control type="file" name="CV File" />
        </Form.Group>
        <FloatingLabel
          controlId="career.message"
          label={message}
          className="mb-2"
        >
          <Form.Control
            as="textarea"
            name="Message"
            rows="4"
            placeholder={message}
            style={{ height: 140 }}
          />
        </FloatingLabel>
        <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
          <Turnstile
            sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
            theme="light"
            onError={() => setDisable(true)}
            onExpire={() => setDisable(true)}
            onVerify={() => setDisable(false)}
          />
          <Button
            type="submit"
            variant="dark"
            className="d-block ms-auto px-5"
            disabled={disable || loading}
          >
            {send}
            {loading && (
              <Spinner animation="border" size="sm" className="ms-2" />
            )}
          </Button>
        </div>
        {submitStatus && (
          <Alert
            variant={submitStatus.type}
            className="mt-2 text-center"
            onClose={() => setSubmitStatus()}
            dismissible
          >
            {submitStatus.message}
          </Alert>
        )}
      </Form>
      <div className="mx-auto mx-lg-0" style={{ maxWidth: 350 }}>
        <PrismicRichText field={info.richText} />
      </div>
    </>
  );
};

export default ContactFormCareers;
